*{
  font-family: 'Montserrat', sans-serif !important; 
}

.pad {
  padding: 7px;
  background: #6363637a;
  color: rgb(255, 255, 255);
  border-radius: 2px;

  transition: background-color 0.3s ease;
}

.pad:hover {
  background: #383838b7; /* Updated background color on hover */
  color: rgb(255, 255, 255);
}




.back_other{
  background: #fff2dd !important;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat !important;
background-size: cover !important;
background-position: center  !important;
}


.btc{
  position: fixed;
  bottom: 0;
  right: 0;
  width: 32%;
}

.about{
  color: #402d28;

  margin-top: 0;
  margin-bottom: 35px;
  font-family: Cheddargothic sans, Impact, sans-serif;
  font-size: 4rem;
  font-weight: 500;
  line-height: 110%;
  text-align: start;
  font-size: 30px;
  font-weight: 800;
}


@media screen and (min-width:1100px) {
  
.about{
  color: #402d28;

  margin-top: 0;
  margin-bottom: 35px;
  font-family: Cheddargothic sans, Impact, sans-serif;
  font-size: 4rem;
  font-weight: 500;
  line-height: 110%;
  text-align: start;
  font-size: 60px;
  font-weight: 800;
}
  }
  .aboutp{
    z-index: 10;
    color: #000000;
    text-align: start;
    font-size: 10px !important;
    font-weight: 500 !important;
  }
  
  
  @media screen and (min-width:1100px) {
    
  .aboutp{
    z-index: 10;
    color: hsl(0, 0%, 0%);
    text-align: start;
    font-size: 16px !important;
    font-weight: 600 !important;
  }
    }
  


.back_of_market{
  background: #7e7e7eec !important;
  border: 1px solid #ffffff00 !important;
  border-radius: 10px !important;
  color: #ffffff00 !important;
}

.h2{
  font-size: 18px !important;
  font-weight: 700 !important;
}






















.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 1px !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}



.input-group {

  width: 60% !important;
}





@media screen and (min-width:1100px) {
    
  .input-group {

    width: 100% !important;
  }
    }







    .home_list-wrap {

  }



  ::-webkit-scrollbar {
    width: 10px !important;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1 !important; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888 !important; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555 !important; 
  }


  .back_of_form{
    background-color: #5555555c;
    border-radius: 20px !important;
  }



  .TOP_FAQ{
      background-position: center;
      background-attachment: fixed;
      background-image: url('./Component/AboutPage/rock.jpg');
  }








  .about_q{
   
  
    margin-top: 0;
    margin-bottom: 35px;
    font-family: Cheddargothic sans, Impact, sans-serif;
    font-size: 2rem;
    font-weight: 500;
    line-height: 110%;
    text-align: start;
    font-size: 20px;
    font-weight: 600;
  }
  
  
  @media screen and (min-width:1100px) {
    
  .about_q{
  
  
    margin-top: 0;
    margin-bottom: 35px;
    font-family: Cheddargothic sans, Impact, sans-serif;

    font-weight: 500;
    line-height: 110%;
    text-align: start;
    font-size: 30px;
    font-weight: 600;
  }
    }



    .about_a{

    
      margin-top: 0;
      margin-bottom: 35px;
      font-family: Cheddargothic sans, Impact, sans-serif;
      font-size: 1rem !important;
      font-weight: 400 !important;
      line-height: 110%;
      text-align: start;
      font-size: 20px;
      font-weight: 600;
    }
    
    
    @media screen and (min-width:1100px) {
      
    .about_a{
      margin-top: 0;
      margin-bottom: 2%;
      font-family: Cheddargothic sans, Impact, sans-serif;
      line-height: 110%;
      text-align: start;
      font-size: 20px !important;
      font-weight: 600;
    }
      }
  




 .backofbulls{
  background-color: #88888810 !important;
  box-shadow:none !important;
} 
.backofbulls:hover{
  background-color: #8a00c5ed !important;
  box-shadow:none !important;
  color: #fff !important;
  border-radius: 10px;
} 



.pad_faq{
  padding: 10px !important ;
  background-color: #402d283a !important;
 }   
 .pad_faq:hover{
  background-color: #8a00c5ed !important;
  box-shadow:none !important;
  color: #fff !important;
  border-radius: 10px;
} 


.Tokenimage{
width: 100%;
}

@media screen and (min-width:1100px) {
      
  .Tokenimage{
     width: 100%;
  }
    }


    .x-logo-team{
      background-color: #1a1a1bd6 !important;
   
      padding: 5px !important;
    }

    .mainContent {
      background: url('./bulls.png') !important;
      height: 100vh;
      width: 100%;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: center !important;
      background-color: #1a1a1bd6;
    }
    
    /* Media Query for Mobile Devices (up to 768px width) */
    @media screen and (max-width: 768px) {
      .mainContent {
   
        background: url('./bulls.jpg') !important;
        height: 100vh;
        width: 100%;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        background-position: center !important;
        background-color: #1a1a1bd6;
      }
    }
    


    
.back_other_home{
  background: #fff2dd !important;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat !important;
background-size: cover !important;
background-position: center  !important;
}

.colorham {
  color: #d97fffed !important;
  transform: rotate(90deg);
  position: relative;
  top: -7px;
}

.mintimg{
  width: 100% !important;
  height: 250px !important;

 border-radius: 20px;
}



@media screen and (min-width:1100px) {
      
  .mintimg{
    width: 100% !important;
    height: 450px !important;
    border-radius: 20px;
  }
    }

























.centeritall{
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}






/* CSS */
.button-86 {
  all: unset;
  width: 100px;
  height: 30px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-86::after,
.button-86::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all .4s;
}

.button-86::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #460088;
  border-radius: 10px;
}

.button-86::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.button-86:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.button-86:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.button-86:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}




















input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  color: #fff;
  font-weight: 600 !important;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-radius: 10px !important;
  background-color: #c6c9dfb3 !important;
  box-sizing: border-box;
}

input[type=submit] {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;

  border-radius: 10px !important;
  cursor: pointer;
  background-color: #030a38 !important;
}

input[type=submit]:hover {
  background-color: #45a049;
}

.div {
  border-radius: 20px;
  background-color: #7c00a2;
  padding: 50px;
  width: 100%;
}



@media screen and (min-width:1100px) {
      
  .div {
    border-radius: 20px;
    background-color: #7c00a2;
    padding: 100px;
    width: 60%;
  }
  
    }





    
.back_other_wl{
  background: #fff2dd !important;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat !important;
background-size: cover !important;
background-position: center  !important;
}
